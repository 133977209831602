<template>
  <VContent>
    <template slot="right">
      <span>المصارف</span>
    </template>
    <template slot="left">
      <b-button variant="info" size="مل" class="text-light" v-b-modal.bank-modal
        >اضافة</b-button
      >
    </template>
    <template slot="content">
      <b-table
        :items="items"
        :fields="fields"
        :tbody-transition-props="transProps"
        tbody-tr-class="text-muted"
        responsive
        table-class="table-font h-100"
      >
        <template #cell(id)="data">
          {{ (page - 1) * 10 + data.index + 1 }}
        </template>
        <template v-slot:cell(name)="{ item }">
          <img
            :src="ImageURL(item)"
            width="35"
            height="35"
            class="bg-info rounded-circle mx-3"
          />
          {{ item.name }}
        </template>
        <template v-slot:cell(email)="{ item }">
          <div class="p-2 rounded-pill green" v-if="item.email">
            {{ item.email }}
          </div>
          <p v-else>لا يوجد</p>
        </template>
        <template v-slot:cell(type)="{ item }">
          <div
            class="p-2 rounded-pill"
            :class="[item.type ? 'orange' : 'purple']"
          >
            {{ item.type ? "تجاري" : "أسلامي" }}
          </div>
        </template>
        <template v-slot:cell(created_at)="{ item }">{{
          new Date(item.created_at).toISOString().slice(0, 10)
        }}</template>
        <template v-slot:cell(actions)="{ item }">
          <b-dropdown size="lg" variant="link" no-caret>
            <template #button-content>
              <b-icon icon="three-dots" animation="cylon"></b-icon>
            </template>
            <b-dropdown-item @click="getByID(item.id, true)">
              <b-icon
                icon="file-earmark-medical"
                scale="0.5"
                class="ml-2"
              ></b-icon>
              عرض</b-dropdown-item
            >
            <b-dropdown-item @click="getByID(item.id, false)">
              <b-icon icon="pencil-square" scale="0.5" class="ml-2"></b-icon>
              تعديل</b-dropdown-item
            >
            <b-dropdown-item @click="remove(item.id)">
              <b-icon icon="archive" scale="0.5" class="ml-2"></b-icon>
              حذف</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </b-table>
    </template>
    <template slot="modals">
      <BankModal :readonly="readonly" @close="readonly = false"></BankModal>
    </template>
    <template slot="bottom">
      <b-pagination
        v-model="page"
        :current-page="meta.current_page"
        :per-page="meta.per_page"
        :total-rows="meta.last_page * meta.per_page"
      ></b-pagination>
    </template>
  </VContent>
</template>

<script>
import BankModal from "./components/bankModal";
import {
  successAlert,
  errorAlert,
  showAlertWithConfirm,
  ImageURL,
} from "@/utils/helper";

export default {
  components: { BankModal },
  data() {
    return {
      readonly: false,
      page: 1,
      transProps: {
        name: "flip-list",
      },
      fields: [
        {
          key: "id",
          label: "أ",
          sortable: true,
        },
        {
          key: "name",
          label: "أسم المصرف",
          sortable: true,
        },
        {
          key: "email",
          label: "البريد الألكتروني",
          sortable: true,
        },
        {
          key: "phone",
          label: "رقم الهاتف",
          sortable: true,
        },
        {
          key: "type",
          label: "نوع المصرف",
          sortable: true,
        },
        {
          key: "location",
          label: "العنوان",
          sortable: true,
        },
        {
          key: "created_at",
          label: "تاريخ  الأنضمام",
          sortable: true,
        },
        {
          key: "actions",
          label: "العمليات",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.admin.banks.list;
    },
    meta() {
      return this.$store.state.admin.banks.meta;
    },
  },
  watch: {
    page() {
      this.getAll();
    },
  },
  mounted() {
    this.getAll();
  },
  methods: {
    getAll() {
      this.$store.dispatch("admin/banks/get", this.page);
    },
    getByID(id, status) {
      this.$store
        .dispatch("admin/banks/show", id)
        .then(() => {
          this.readonly = status;
          this.$bvModal.show("bank-modal");
        })
        .catch(() => errorAlert());
    },
    remove(id) {
      showAlertWithConfirm().then((response) => {
        if (response.isConfirmed) {
          this.$store
            .dispatch("admin/banks/delete", id)
            .then(() => {
              successAlert();
              this.$store.commit("admin/banks/DELETE_BANK", id);
            })
            .catch((error) =>
              errorAlert(
                error.response ? error.response.data.message : "فشل الحذف"
              )
            );
        }
      });
    },
    ImageURL(item) {
      return item.logo ? item.logo : ImageURL("user.jpg");
    },
  },
};
</script>

<style lang="scss">
td[aria-colindex="1"] {
  text-align: right;
}
td[aria-colindex="2"] {
  text-align: center;
}
td[aria-colindex="3"] {
  direction: ltr;
}
td[aria-colindex="5"] {
  overflow-x: hidden;
  width: 200px;
}
</style>
